import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import {Message} from "element-ui";

Vue.use(VueRouter);

const routes = [
        {
            path: "/login",
            name: "Login",
            component: () => import( "../views/Login.vue"),
        },
        {
            path: "/register",
            name: "Register",
            component: () => import( "../views/Register.vue"),
        },
    {
        path: "/404",
        name: "404",
        component: () => import( "../views/404.vue"),
    }
    ]
;

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// 重置路由集合
export const resetRoutes = () => {
    router.matcher = new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes,
    });
}

export const setRoutes = () => {
    // 获取浏览器缓存的菜单数据
    const localMenus = localStorage.getItem("menus")
    if (localMenus) {
        const currentRoutes = router.getRoutes().map(v => v.name)
        if (!currentRoutes.includes("manage")) {

            const manageRoute = {
                path: "/",
                name: "manage",
                component: () => import( "../views/Manage.vue"),
                children: []
            }
            const menus = JSON.parse(localMenus)
            menus.forEach(item => {
                if (item.path) {
                    const itemMenu = {
                        path: item.path.replace("/", ""),
                        name: item.name,
                        component: () => import('../views/' + item.pagePath + '.vue')
                    }
                    manageRoute.children.push(itemMenu)
                } else if (item.children.length) {
                    item.children.forEach(item => {
                        const itemMenu = {
                            path: item.path.replace("/", ""),
                            name: item.name,
                            component: () => import('../views/' + item.pagePath + '.vue')
                        }
                        manageRoute.children.push(itemMenu)
                    })
                }
            })
            router.addRoute(manageRoute)
        }
    }
}

setRoutes()
router.beforeEach((to, from, next) => {
    localStorage.setItem("currentPathName", to.name)
    store.commit("setPath")
    const localMenus = localStorage.getItem("menus")
    if (!to.matched.length) {
        if (localMenus) {
            next("/404")
        } else {
            Message.warning("请先登录")
            next("/login")
        }
    }
    next()
})

export default router;
